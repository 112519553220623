import React from 'react';
import Carousel from 'react-multi-carousel';
import Image from '@/components/elements/Image';
import { SlideType } from './SliderSection.types';
import * as styles from './SliderSection.module.scss';

import 'react-multi-carousel/lib/styles.css';

const RESPONSIVE_SETTINGS = {
  desktop: {
    breakpoint: { max: 3000, min: 768 },
    items: 1,
    partialVisibilityGutter: 0,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
    partialVisibilityGutter: 30,
  },
};

export default function SliderSection({ slides }: { slides: SlideType[] }) {
  return (
    <div className={styles.slider}>
      <Carousel
        swipeable
        draggable={false}
        showDots
        renderDotsOutside
        arrows
        centerMode={false}
        responsive={RESPONSIVE_SETTINGS}
        ssr={true}
        infinite
        autoPlay={false}
        transitionDuration={500}
        removeArrowOnDeviceType={['mobile']}
        dotListClass={styles.dotNav}
        partialVisible
      >
        {slides.map(({ _uid, image, quote, name, title_or_company }) => (
          <div className={styles.slide} key={_uid}>
            <div className={styles.imageWrapper}>
              <Image data={image} fluid className={styles.image} />
            </div>
            <div className={`${styles.contentWrapper}`}>
              <div className={`box ${styles.content}`}>
                <mark className={styles.quoteMark}>&ldquo;</mark>
                <p className={` ${styles.quote}`}>{quote} </p>
                <p className={styles.name}>{name} </p>
                <p className={styles.role}>{title_or_company}</p>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
}
