// extracted by mini-css-extract-plugin
export var content = "SliderSection-module--content--3a540";
export var contentWrapper = "SliderSection-module--contentWrapper--6c175";
export var dotNav = "SliderSection-module--dotNav--442f9";
export var image = "SliderSection-module--image--7df07";
export var imageWrapper = "SliderSection-module--imageWrapper--00871";
export var name = "SliderSection-module--name--a8081";
export var opacityEnter = "SliderSection-module--opacityEnter--c307f";
export var opacityExit = "SliderSection-module--opacityExit--66acc";
export var position = "SliderSection-module--position--68b6f";
export var quote = "SliderSection-module--quote--d7e28";
export var quoteMark = "SliderSection-module--quoteMark--2fb95";
export var role = "SliderSection-module--role--a244c";
export var rollDown = "SliderSection-module--rollDown--7534e";
export var rollUp = "SliderSection-module--rollUp--0a0e9";
export var slide = "SliderSection-module--slide--974cb";
export var slideInDown = "SliderSection-module--slideInDown--32590";
export var slideOutUp = "SliderSection-module--slideOutUp--ce285";
export var slider = "SliderSection-module--slider--dfc80";
export var splashEnter = "SliderSection-module--splashEnter--22f57";
export var splashExit = "SliderSection-module--splashExit--07de4";